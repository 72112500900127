import { gql, QueryResult, useLazyQuery } from '@apollo/client';
import { useEffect, useMemo, useRef } from 'react';
import { backendResponse } from '../types/backendResponse';
import { backendClient } from '../utilities/BackendAPI';

export const GET_ORDER_PRICE = gql`
  query getOrderPrice($data: OrderPriceInput!, $countPaidPasses: Boolean) {
    getOrderPrice(data: $data, countPaidPasses: $countPaidPasses) {
      success
      error
      data {
        totalPrice
        fees {
          name
          amount
        }
        passes {
          passId
          passInfoId
          price
        }
      }
    }
  }
`;

export type GET_ORDER_PRICE_VARS = {
  data: {
    passes: {
      passId: string;
      passInfoId: string;
      startDate?: Date;
      endDate?: Date;
      registrationId?: string;
    }[];
  };
  // NOTE: DO NOT SET TRUE FOR ANY OTHER PURPOSE THAN RECEIPT PAGES/ORDER SUMMARY PAGES
  countPaidPasses?: boolean;
};

export type feeType = {
  name?: string;
  amount?: number;
};

export type GET_ORDER_PRICE_RES = {
  getOrderPrice: backendResponse<{
    totalPrice: number;
    fees?: feeType[];
    passes: {
      passId: string;
      passInfoId: string;
      price: number;
    }[];
  }>;
};

/*
  @return boolean
    true: lists are identical
    false: lists are not the same
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function deepComparePasses<T extends pseudoPass>(next: T[], prev?: T[]): boolean {
  if (next.length !== prev?.length) return false;
  for (let i = 0; i < next.length; i += 1) {
    const n = next[i];
    const p = prev[i];
    // if (n === p) continue;
    if (n.passId !== p.passId) return false;
    if (n.passInfoId !== p.passInfoId) return false;
    if (n.startDate !== p.startDate) return false;
    if (n.endDate !== p.endDate) return false;
    // evaluate addons
    const nadds = n.addons;
    const padds = p.addons;
    // if (nadds === padds) continue;
    if (nadds?.length !== padds?.length) return false;
    const itterLen = nadds?.length || 0;
    for (let j = 0; j < itterLen; j += 1) {
      if (nadds?.[j] !== padds?.[j]) return false;
    }
  }
  return true;
}

function compareString<T extends pseudoPass>(obj: T[]): string {
  let res = '';
  obj.forEach(p => {
    res += `|${p.startDate}-${p.endDate},${p.passId},${p.passInfoId},${JSON.stringify(
      p.addons,
    )}`;
  });
  return res;
}

export type PassPrice = {
  passId?: string;
  passInfoId: string;
  price?: number;
};

type pseudoPass = {
  price?: number;
  passId?: string;
  passInfoId: string;
  startDate?: string;
  endDate?: string;
  addons?: string[];
  registrationId?: string;
  address?: string;
};

/*
  @data: this should be memoized. It will no longer create an infinite loop, but will be more efficient
*/
export default function useOrderPrice<T extends pseudoPass>(
  data: T[],
  cfg?: {
    // NOTE: DO NOT SET TRUE FOR ANY OTHER PURPOSE THAN RECEIPT PAGES/ORDER SUMMARY PAGES
    countPaidPasses?: boolean;
    defaultAddress?: string;
  },
): {
  totalPrice: number;
  fees: feeType[];
  passes?: PassPrice[];
  passesByType?: Record<
    string, // passInfoId
    PassPrice[] // full pass price object array
  >;
  getOrderPriceAsync: () => Promise<number>;
  error?: string;
  loading: boolean;
} {
  const prev = useRef<T[]>();
  const [fetch, { data: res, error, loading }] = useLazyQuery<
    GET_ORDER_PRICE_RES,
    GET_ORDER_PRICE_VARS
  >(GET_ORDER_PRICE, {
    fetchPolicy: 'network-only',
    onError: err => {
      console.error(err);
    },
  });

  useEffect(() => {
    // skip refetch if data is deeply identical
    // if (deepComparePasses(data, prev.current)) return;
    prev.current = data;
    const passes = data?.map((p: T, idx) => ({
      passId: p.passId || '' + (idx + 1),
      passInfoId: p.passInfoId,
      startDate: p.startDate ? new Date(p.startDate) : undefined,
      endDate: p.endDate ? new Date(p.endDate) : undefined,
      addons: p.addons || [],
      registrationId: p.registrationId || '',
      address: p.address || cfg?.defaultAddress || undefined,
    }));
    const passesCreatedList = passes?.filter(ele => ele.passId); // if passID is available then it will call this endPoint
    if (passesCreatedList.length > 0) {
      fetch({
        variables: {
          countPaidPasses: cfg?.countPaidPasses,
          data: {
            passes,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareString(data), fetch]);

  const calculateTotal = () => {
    let total = 0;
    data.forEach((ele: T) => {
      if (ele.price) {
        total += +ele.price;
      }
    });
    return total;
  };

  const passesByType = useMemo(() => {
    const result: Record<string, PassPrice[]> = {};
    res?.getOrderPrice.data?.passes.forEach(p => {
      const line = result[p.passInfoId] || [];
      line.push(p);
      result[p.passInfoId] = line;
    });
    return result;
  }, [res]);

  const getOrderPriceAsync = async () => {
    return new Promise((resolve, reject) => {
      const formattedPasses = data.map((p: T, idx) => ({
        passId: p.passId || '' + (idx + 1),
        passInfoId: p.passInfoId,
        startDate: p.startDate ? new Date(p.startDate) : undefined,
        endDate: p.endDate ? new Date(p.endDate) : undefined,
        addons: p.addons || [],
        registrationId: p.registrationId || '',
      }));

      fetch({
        variables: {
          data: {
            passes: formattedPasses,
          },
        },
      }).then((res: QueryResult<GET_ORDER_PRICE_RES, GET_ORDER_PRICE_VARS>) => {
        if (res?.error) {
          reject('Could not get order price. Observed values may be unreliable.');
        } else {
          resolve(res?.data?.getOrderPrice.data?.totalPrice || calculateTotal());
        }
      });
    });
  };

  return {
    totalPrice: res?.getOrderPrice.data?.totalPrice || calculateTotal(),
    fees: res?.getOrderPrice.data?.fees || [],
    passes: res?.getOrderPrice.data?.passes || data,
    passesByType,
    getOrderPriceAsync: () => getOrderPriceAsync() as Promise<number>,
    loading,
    error:
      res?.getOrderPrice.error ||
      (error
        ? 'Could not get order price. Observed values may be unreliable.'
        : undefined),
  };
}

export const getOrderPrice = async (vars: GET_ORDER_PRICE_VARS) =>
  backendClient.query<GET_ORDER_PRICE_RES, GET_ORDER_PRICE_VARS>({
    query: GET_ORDER_PRICE,
    variables: vars,
  });
