import { DocumentNode, gql, useLazyQuery } from '@apollo/client';
import React, { ReactElement, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { CommunityContext } from '../../communityConfigs/communityContextProvider';
import GenericAlert from '../../components/genericAlert/genericAlert';
import GenericButton from '../../components/genericButton/genericButton';
import InputField from '../../components/inputField/inputField';
import { backendResponse, backendTokenResponse } from '../../types/backendResponse';
import { backendClient } from '../../utilities/BackendAPI';
import store from '../../utilities/store';
import useTextSelection, { textOptionsType } from '../../utilities/useTextSelection';
import style from './login.module.css';

export const LOGIN_QUERY = (role: string): DocumentNode => gql`
  query LoginUser(
    $password: String!,
    $email: String!,
  ) {
    loginUser(
      password: $password,
      email: $email
    ) {
      success
      error
      token
      data {
        userId
        firstName
        lastName
        email
        roles
        ${role === 'guest' ? 'isReferredUser' : ''}
        ${role === 'resident' ? 'fastPassUser' : ''}
        ${role === 'resident' ? 'profileStatus' : ''}
        ${role === 'resident' ? 'applicationId' : ''}
      }
    }
  }
`;

type LOGIN_QUERY_RES = {
  loginUser: backendTokenResponse<{
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<string>;
    isReferredUser?: boolean;
    fastPassUser?: boolean;
    applicationId?: string;
    profileStatus?: string;
  }>;
};

type loginTextConfig = {
  communityTitle: string | ReactElement | ((communityName: string) => string);
  communitySubTitle: string | ReactElement;
};

const loginTexts: textOptionsType<loginTextConfig> = {
  default: {
    admin: {
      communityTitle: cn => `Welcome to the ${cn} Community Admin Portal`,
      communitySubTitle: ' ',
    },
    resident: {
      communityTitle: cn => `Welcome to the ${cn} Community Host Portal`,
      communitySubTitle: ' ',
    },
    guest: {
      communityTitle: cn => `Welcome to the ${cn} Community Guest Registration Portal`,
      communitySubTitle:
        "Registration is required for all guests entering the community. If you've previously created an account, please login below to register a new visit and receive your community access pass.",
    },
    vendor: {
      communityTitle: cn => `Welcome to the ${cn} Community Vendor Registration Portal`,
      communitySubTitle:
        "Registration is required for all vendors entering the community. If you've previously created an account, please login below to register a new visit and receive your community access pass.",
    },
    default: {
      communityTitle: communityName => `Welcome to ${communityName}`,
      communitySubTitle: '',
    },
  },
  wildheron: {
    resident: {
      communityTitle: cn => `Welcome to the ${cn} Community Resident Portal`,
      communitySubTitle: ' ',
    },
  },
  grandcomplex: {
    guest: {
      communityTitle: 'Welcome to the Grand Complex Guest Parking Terminal',
      communitySubTitle: (
        <div>
          A community pass is required to use the Grand Complex parking garage. Please
          follow the instructions below to purchase your community pass.
          <b>
            &nbsp;Please note that you are only allowed to park one vehicle in the parking
            garage during your stay.
          </b>
          &nbsp;Your host should have previously emailed you your login information. If
          you have not received or have lost your login information, please contact your
          host. The price per pass is $10.00.
        </div>
      ),
    },
    admin: {
      communityTitle: "Welcome to SymLiv's Grand Complex Community Admin Portal",
      communitySubTitle: ' ',
    },
    resident: {
      communityTitle: 'Welcome to the Community Owner Portal',
      communitySubTitle:
        'Please log in below to begin managing your community experience.',
    },
    vendor: {
      communityTitle: 'Welcome to the Grand Complex Community Vendor Registration Portal',
      communitySubTitle:
        "Registration is required for all vendors entering the community. If you've previously created an account, please login below to register a new visit and receive your community access pass.",
    },
  },
  sipoa: {
    admin: {
      communityTitle: 'Welcome to the Seabrook Island Community Admin Portal',
      communitySubTitle: ' ',
    },
    resident: {
      communityTitle:
        'Welcome to the Seabrook Island Community Resident Registration Portal',
      communitySubTitle:
        "Registration is required for all residents entering the community. If you've previously created an account, please login below to register a new visit and receive your community access pass.",
    },
    guest: {
      communityTitle:
        'Welcome to the Seabrook Island Community Guest Registration Portal',
      communitySubTitle:
        "Registration is required for all guests entering the community. If you've previously created an account, please login below to register a new visit and receive your community access pass.",
    },
    vendor: {
      communityTitle:
        'Welcome to the Seabrook Island Community Vendor Registration Portal',
      communitySubTitle:
        "Registration is required for all vendors entering the community. If you've previously created an account, please login below to register a new visit and receive your community access pass.",
    },
  },
  palmettodunes: {
    admin: {
      communityTitle: 'Welcome to the Palmetto Dunes Community Admin Portal',
      communitySubTitle: ' ',
    },
    resident: {
      communityTitle: 'Welcome to Palmetto Dunes Short-Term Rental Registration Portal',
      communitySubTitle:
        'Registration is required for all owners who rent their property in the short-term rental market in the Palmetto Dunes community. A short-term rental is the sharing of any property for any period of less than 90 continuous days for monetary or other consideration within Palmetto Dunes. Examples include weekly (7 day), weekend (3 day), or monthly (30 day) rentals. Registration provides the necessary owner and property management information allowing the POA to quickly contact the appropriate individuals about property situations and guest needs.',
    },
    guest: {
      communityTitle: 'Welcome to the Palmetto Dunes Community Guest Registration Portal',
      communitySubTitle:
        "Registration is required for all guests entering the community. If you've previously created an account, please login below to register a new visit and receive your community access pass.",
    },
    vendor: {
      communityTitle:
        'Welcome to the Palmetto Dunes Community Vendor Registration Portal',
      communitySubTitle:
        "Registration is required for all vendors entering the community. If you've previously created an account, please login below to register a new visit and receive your community access pass.",
    },
  },
  cb: {
    admin: {
      communityTitle: 'Welcome to the Crystal Beach Community Admin Portal',
      communitySubTitle: ' ',
    },
    resident: {
      communityTitle:
        'Welcome to the Crystal Beach Community Resident Registration Portal',
      communitySubTitle:
        "Registration is required for all residents entering the community. If you've previously created an account, please login below to register a new visit and receive your community access pass.",
    },
    guest: {
      communityTitle: 'Welcome to the Crystal Beach Community Guest Registration Portal',
      communitySubTitle:
        "Registration is required for all guests entering the community. If you've previously created an account, please login below to register a new visit and receive your community access pass.",
    },
    vendor: {
      communityTitle: 'Welcome to the Crystal Beach Community Vendor Registration Portal',
      communitySubTitle:
        "Registration is required for all vendors entering the community. If you've previously created an account, please login below to register a new visit and receive your community access pass.",
    },
  },
  watercolor: {
    admin: {
      communityTitle: 'Welcome to the WaterColor Community Admin Portal',
      communitySubTitle: ' ',
    },
    resident: {
      communityTitle: 'Welcome to the WaterColor Community Host Portal',
      communitySubTitle: ' ',
    },
    guest: {
      communityTitle: 'Welcome to the WaterColor Community Guest Registration Portal',
      communitySubTitle:
        "Registration is required for all guests entering the community. If you've previously created an account, please login below to register a new visit and receive your community access pass.",
    },
    vendor: {
      communityTitle: 'Welcome to the WaterColor Community Vendor Registration Portal',
      communitySubTitle:
        "Registration is required for all vendors entering the community. If you've previously created an account, please login below to register a new visit and receive your community access pass.",
    },
  },
  oceanlakes: {
    vendor: {
      communityTitle: 'Welcome to the Ocean Lakes Contractor Portal',
      communitySubTitle:
        'Registration is required for all contractors entering the community. If you have previously created an account, please log in below to access your account and register a new visit or get a pass.',
    },
  },
};

interface Props {
  role: 'guest' | 'resident' | 'admin' | 'vendor' | 'symliv';
  successHref: string;
  disableCreation?: boolean;
  referedUserHref?: string;
}

const RESIDENT_PROFILE = gql`
  query getResidentProfile {
    getResidentProfile {
      success
      error
      data {
        userId
      }
    }
  }
`;
type RESIDENT_PROFILE_VARS = never;
type RESIDENT_PROFILE_RES = {
  getResidentProfile: backendResponse<{
    userId: string;
  }>;
};

export default function Login(props: Props): ReactElement {
  const { role, successHref, disableCreation, referedUserHref } = props;

  const { t } = useTranslation();

  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const { bigLogo, name: communityName, communityId } = useContext(CommunityContext);
  const { communityTitle, communitySubTitle } = useTextSelection(loginTexts, role);

  const translatedCommunitySubtitle = useMemo(() => {
    const res = t(`${communitySubTitle}`);
    if (res === '[object Object]') return communitySubTitle;
    return res;
  }, [communitySubTitle, t]);

  /**
   * Helper function to determine the url a user should be redirected to next.
   * This function should capture any edge cases like resident/host and refered users.
   * @param isReferredUser
   * @returns Url to redirect to
   */
  const pickNextUrl = async (isReferredUser = false): Promise<string> => {
    // user should go to host home if we can garuntee the non-existence of the resident profile
    if (role === 'resident') {
      const residentHostNext: string | void = await backendClient
        .query<RESIDENT_PROFILE_RES, RESIDENT_PROFILE_VARS>({
          query: RESIDENT_PROFILE,
        })
        .then(residentData => {
          if (
            residentData?.data?.getResidentProfile &&
            !residentData.data.getResidentProfile.data
          ) {
            return '/host-portal';
          }
        })
        .catch(error => {
          console.log(error);
        });
      if (typeof residentHostNext === 'string') {
        return residentHostNext;
      }
    }
    if (referedUserHref && isReferredUser) {
      return referedUserHref;
    }
    const nextHref = sessionStorage.getItem('login-next-href');
    if (nextHref) {
      sessionStorage.setItem('login-next-href', '');
      return nextHref;
    }
    return successHref;
  };

  const hiddenAlert = () => {
    setTimeout(() => {
      setAlert('');
    }, 4000);
  };

  const [doLogin] = useLazyQuery<LOGIN_QUERY_RES>(LOGIN_QUERY(role), {
    fetchPolicy: 'network-only',
    onError: async e => {
      if (process?.env?.REACT_APP_DEBUG === 'true') {
        store.dispatch({
          type: 'user/login',
          payload: {
            token: 'just loged in in debug mode',
            user: {
              firstName: 'test',
              lastName: 'mode',
              email: 'ex@ex.com',
              roles: ['admin'],
            },
          },
        });
        history.push(await pickNextUrl());
      } else {
        setAlert(e.message);
        setAlertColor('red');
        hiddenAlert();
      }
    },
    onCompleted: async d => {
      if (d.loginUser.success) {
        store.dispatch({
          type: 'user/login',
          payload: {
            token: d.loginUser.token,
            user: d.loginUser.data,
          },
        });
        history.push(await pickNextUrl());
      } else if (process?.env?.REACT_APP_DEBUG === 'true') {
        store.dispatch({
          type: 'user/login',
          payload: {
            token: 'just loged in in debug mode',
            user: {
              firstName: 'test',
              lastName: 'mode',
              email: 'ex@ex.com',
              roles: ['admin'],
            },
          },
        });
        history.push(await pickNextUrl());
      } else {
        setAlert(d.loginUser.error);
        setAlertColor('red');
        hiddenAlert();
      }
    },
  });

  const loginHandler = () => {
    doLogin({
      variables: {
        password,
        email,
      },
    });
  };

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <div className={`${style.box__left}  linerGradient `}>
          <img
            src={bigLogo}
            alt=""
            className={
              communityId === 'watercolor' ||
              communityId === 'oceanlakes' ||
              communityId === 'isleworth' ||
              communityId === 'pinnacleport' ||
              communityId === 'debordieu' ||
              communityId === 'carillonbeach'
                ? style.box__imgPostion
                : style.box__img
            }
          />
          {disableCreation ? null : (
            <>
              <h3 className={`${style.box__mainTitleLeft} textColorWhite `}>
                {t("Don't have an account?")}
              </h3>
              <Link to="/create-account">
                <div className={style.box__btnLeft}>
                  <div>
                    <GenericButton
                      size="medium"
                      color="white"
                      title={t('Create Account')}
                    />
                  </div>
                </div>
              </Link>
            </>
          )}
        </div>
        <form
          className={style.box__right}
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            loginHandler();
          }}
        >
          <div className={style.box__titleBoxRight}>
            <h3 className={`${style.box__mainTitleRight} textColorDark `}>
              {t(
                `${
                  typeof communityTitle === 'function'
                    ? communityTitle(communityName)
                    : communityTitle
                }`,
              )}
            </h3>
            <p className={`${style.box__paragraph} textColorMedium `}>
              {translatedCommunitySubtitle}
            </p>
          </div>

          <h5 className={`${style.box__subTitle} textColorDark `}>
            {t('Please log in to your account')}
          </h5>
          <div className={style.alert}>
            <div className={style.alertBox}>
              <GenericAlert color={alertColor} hidden={!alert} title={'User name or password are incorrect. If you are experiencing trouble logging in please contact Symliv support at 435-612-2668 Ext 2'} />
            </div>
          </div>
          <div className={style.inputLong}>
            <div className={style.box__inputLong}>
              <div className={style.box__inputField}>
                <InputField
                  smallSize
                  htmlFor="email-inpt"
                  labelTitle="Email Address"
                  inputType="email"
                  inputValue={email}
                  highlightOnFocus
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={style.box__inputLong}>
              <div className={style.box__inputField}>
                <InputField
                  smallSize
                  highlightOnFocus
                  htmlFor="password-inpt"
                  labelTitle="Password"
                  inputType="password"
                  inputValue={password}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={style.flexBox}>
            <Link to="/forgot-password">
              <h6 className={`${style.box__forgotPassWord} textColorMedium `}>
                {t('Forgot Password?')}
              </h6>
            </Link>
            <div className={style.box__btnRight}>
              <GenericButton size="medium" color="blue" title="Login" type="submit" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

Login.defaultProps = {
  disableCreation: false,
  referedUserHref: undefined,
};
