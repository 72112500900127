import { gql, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommunityContext } from '../common_lib_front/communityConfigs/communityContextProvider';
import { backendResponse } from '../common_lib_front/types/backendResponse';
import { InfoConfigValues } from '../common_lib_front/types/InfoConfigValues';
import {
  VehicleInfoConfig,
  newVehicleInfoConfig,
} from '../common_lib_front/types/vehicleInfo';
import {
  GET_STEP_ONE,
  GET_STEP_ONE_QUERY,
  GET_STEP_ONE_RESPONSE,
} from '../pages/registration/registrationHome/registrationHomeRequests';

const GET_COMMUNITY_PASSES = gql`
  query GetPassInfosByCommunity($communityId: String!) {
    getPassInfosByCommunity(complete: true, communityId: $communityId) {
      success
      error
      data {
        passInfoId
        portal
        name
        description
        customInstructions
        hidePrint
        vehicleInfo {
          color
          fleetNumber
          licensePlate
          licensePlateState
          licensePrimaryDriver
          make
          passInfoId
          primaryDriverName
          type
          vehicleModel
          name
          completeLater
        }
        priceInfo {
          passInfoId
          isFree
          price
          chargePerDay
          chargeForFullCapacity
        }
        durationInfo {
          type
          duration1
        }
        deliveryMethod
      }
    }
  }
`;
type GET_COMMUNITY_PASSES_VARS = {
  communityId: string;
};
export type PassInfoData = {
  passInfoId: string;
  portal: string;
  name: string;
  description: string;
  price: number;
  customInstructions: string;
  hidePrint: boolean;
  vehicleInfo: {
    color: keyof typeof InfoConfigValues;
    fleetNumber: keyof typeof InfoConfigValues;
    licensePlate: keyof typeof InfoConfigValues;
    licensePlateState: keyof typeof InfoConfigValues;
    licensePrimaryDriver: keyof typeof InfoConfigValues;
    make: keyof typeof InfoConfigValues;
    passInfoId: keyof typeof InfoConfigValues;
    primaryDriverName: keyof typeof InfoConfigValues;
    type: keyof typeof InfoConfigValues;
    vehicleModel: keyof typeof InfoConfigValues;
    name: keyof typeof InfoConfigValues;
    completeLater: keyof typeof InfoConfigValues;
  };
  priceInfo: null | {
    passInfoId: string;
    isFree: boolean;
    price: number;
    chargePerDay?: boolean;
    chargeForFullCapacity?: boolean;
  };
  durationInfo: null | {
    type: number;
    duration1: number;
  };
  deliveryMethod: number;
};
type GET_COMMUNITY_PASSES_RES = {
  getPassInfosByCommunity: backendResponse<PassInfoData[]>;
};

export default function useVehicleConfigs(): {
  passInfoDatas: PassInfoData[];
  vehicleConfig: { [passInfoId: string]: VehicleInfoConfig };
  guestName: string;
} {
  // eslint-disable-line indent
  const [vehicleConfig, setVehicleConfig] = useState<{
    [passInfoId: string]: VehicleInfoConfig;
  }>({});
  const [guestName, setGuestName] = useState<string>('');
  const [passInfoDatas, setPassInfoDatas] = useState<PassInfoData[]>([]);
  const { communityId } = useContext(CommunityContext);
  const { registrationId } = useParams<{ registrationId: string }>();

  // get community pass info configs
  useQuery<GET_COMMUNITY_PASSES_RES, GET_COMMUNITY_PASSES_VARS>(GET_COMMUNITY_PASSES, {
    // query options
    variables: {
      communityId,
    },
    onCompleted: async d => {
      if (!d.getPassInfosByCommunity.data) {
        // TODO: error logic
        return;
      }
      // get vehicle for each passInfoId fetched
      const newVehicleConfigs: typeof vehicleConfig = {};
      d.getPassInfosByCommunity.data
        .filter(p => p.portal === 'guest')
        .forEach(p => {
          newVehicleConfigs[p.passInfoId] = newVehicleInfoConfig(p.vehicleInfo);
        });
      setVehicleConfig(newVehicleConfigs);

      const res = d?.getPassInfosByCommunity.data || [];
      setPassInfoDatas(res);
    },
  });

  const hasRegistrationId = Boolean(registrationId);
  useQuery<GET_STEP_ONE_RESPONSE, GET_STEP_ONE_QUERY>(GET_STEP_ONE, {
    variables: hasRegistrationId ? { registrationId } : undefined,
    fetchPolicy: 'network-only',
    skip: !hasRegistrationId, // Skip the query if registrationId is not present
    onCompleted: d => {
      if (d.getGuestInfoData.data) {
        const fname = d.getGuestInfoData.data?.firstName || '';
        const lname = d.getGuestInfoData.data?.lastName || '';
        setGuestName(`${fname} ${lname}`);
      }
    },
    onError: e => {
      console.log(e);
    },
  });

  return {
    passInfoDatas,
    vehicleConfig,
    guestName,
  };
}
