import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as HamburgerImg } from '../../assets/hamburger.svg';
import { ReactComponent as HamburgerCloseImg } from '../../assets/hamburgerClose.svg';
import { ReactComponent as LanguageIcon } from '../../assets/language.svg';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import store, {
  isLogedIn as reduxIsLogedIn,
} from '../../common_lib_front/utilities/store';
import useMediaQuery from '../../common_lib_front/utilities/useMediaQuery';
import UserProfileButton from '../userProfileButton/userProfileButton';
import style from './header.module.css';

export const GuestHeaderTab = (props: {
  href: string;
  title: string;
  activeRegex: RegExp;
  hidden?: boolean;
}): ReactElement => {
  const { href, title, activeRegex, hidden } = props;
  const { pathname } = useLocation();

  let clickedStyle;
  let boxStyle;
  if (activeRegex.test(pathname)) {
    // CSS FOR WHEN CLICKED
    clickedStyle = style.clicked;
    boxStyle = 'mainColor';
  }

  if (hidden) {
    return <></>;
  }
  return (
    <Link to={href} className={`${style.navItem} textColorMain ${clickedStyle}`}>
      {title}
      <div className={`${boxStyle} ${style.navLine}`} />
    </Link>
  );
};
GuestHeaderTab.defaultProps = {
  hidden: false,
};

export const Header = (): ReactElement => {
  const [isLogedin, setIsLogedin] = useState<boolean>(reduxIsLogedIn());
  const [guestFlowPages, setGuestFlowPages] = useState<boolean>(false);
  useEffect(() => store.subscribe(() => setIsLogedin(reduxIsLogedIn())), [setIsLogedin]);
  const { pathname } = useLocation();
  // this regex test for logo middle patterns. The Issue is that the useLocation hook
  // can only be used inside Route
  const [allowLinks, setAllowLinks] = useState<boolean>(false);
  const [showLinks, setShowLinks] = useState<boolean>(false);
  const smallScreen = useMediaQuery('(max-width: 767px)');
  const { logo, name: communityName, communityId } = useContext(CommunityContext);

  useEffect(() => {
    // this regex test for logo middle patterns. The Issue is that the useLocation hook
    // can only be used inside Route
    // console.log('reevaluating allow links');
    const res =
      !/(\/guest$|\/login|\/create-account|\/claim-invite-account|\/magic-link|\/get-passes|\/education|\/preview-passes|\/payment-request|\/link-disabled|\/forgot-password|\/privacy-policy|\/terms-conditions|\/help)/.test(
        pathname,
      );

    const guestFlowPagesFlg =
      /(\/get-passes|\/education|\/preview-passes|\/payment-request|\/link-disabled|\/help)/.test(
        pathname,
      );

    setGuestFlowPages(guestFlowPagesFlg);

    setAllowLinks(res);
    setShowLinks(false);
  }, [pathname]);

  let headerStyle;
  let invisiableBox;
  let profileBtn;
  let logoBox;
  let logoLayout;
  let profileBox;
  let helpBtn;

  if (isLogedin) {
    // TODO: add style for if userProfile button is shown
    profileBtn = style.profileBtnApper;
    helpBtn = style.helpBtnApper;
  } else {
    //
    profileBtn = style.profileBtnDisapper;
    helpBtn = style.helpBtDisapper;
  }
  if (showLinks) {
    // TODO: add style for if links are shown
    headerStyle = style.header;
    logoBox = style.logoBoxSmall;
    logoLayout = style.logoLeft;
    profileBox = style.profileLogoLeft;
  } else {
    // TODO: add style for if no links are shown
    headerStyle = style.headerLogoMiddle;
    invisiableBox = style.invisibleBoxApper;
    logoBox = style.logoBoxLarge;
    logoLayout = style.logoMiddle;
    profileBox = style.profileLogoMiddle;
  }

  if (allowLinks) {
    headerStyle = style.header;
    logoBox = style.logoBoxSmall;
    logoLayout = style.logoLeft;
    profileBox = style.profileLogoLeft;
    invisiableBox = style.invisibleBoxDisApper;
  }
  const getRedirectLink = () => {
    if (isLogedin) return '/';
    return '#';
  };
  const { t, i18n } = useTranslation();

  return (
    <div className={`${headerStyle} white noShadow`}>
      {smallScreen && allowLinks ? (
        <div className={style.hamburgerBtn}>
          <GenericButton
            title=""
            outline="none"
            color="none"
            icon={() =>
              showLinks ? (
                <HamburgerCloseImg className={style.hamburgerCloseImg} />
              ) : (
                <HamburgerImg className={style.hamburgerImg} />
              )
            }
            clickHandler={() => setShowLinks(!showLinks)}
          />
        </div>
      ) : null}
      <div className={invisiableBox} />
      <div className={`${logoBox} ${guestFlowPages ? `${style.showLogo}` : ''}`}>
        <Link to={getRedirectLink()}>
          {smallScreen && !guestFlowPages ? (
            communityName
          ) : (
            <img src={logo} alt="logo" className={logoLayout} />
          )}
        </Link>
      </div>
      {allowLinks && (smallScreen ? showLinks : true) ? (
        <div className={style.nav}>
          <GuestHeaderTab
            href="/guest/edit-profile"
            title={t('My Profile')}
            activeRegex={/\/guest\/edit-profile/}
          />
          <GuestHeaderTab
            href="/guest/my-passes"
            title={t('My Passes')}
            activeRegex={/\/guest\/my-passes/}
          />
          <GuestHeaderTab
            href="/guest/registration"
            title={t('Purchase Pass')}
            activeRegex={/\/guest\/registration(?!\/rental)/}
            hidden={true}
          />
          {communityId !== 'watercolor' && (
            <GuestHeaderTab
              href="/guest/registration/rental"
              title={t('Register Rental Car')}
              activeRegex={/\/guest\/registration\/rental/}
            />
          )}
        </div>
      ) : null}
      <div className={profileBox}>
        {!guestFlowPages ? (
          <button
            onClick={() => {
              i18n.changeLanguage(i18n.language === 'en' ? 'es' : 'en');
            }}
            className={`${style.language} textColorWhite thirdColor `}
          >
            <span className={style.languageIcon}>
              <LanguageIcon />
            </span>
            {t('otherLanguage')}
          </button>
        ) : null}
        <div className={profileBtn}>
          {isLogedin ? <UserProfileButton /> : null}
          <Link to="/guest/help">
            <p className={`${helpBtn} textColorMain`}>{t('Help')}</p>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Header;
