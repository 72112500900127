import { useMutation, useQuery } from '@apollo/client';
import { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import PassInfo, { newPassInfo } from '../../../common_lib_front/types/passInfo';
import VehicleInfo, { newVehicleInfo } from '../../../common_lib_front/types/vehicleInfo';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
import { passCredOrNumber } from '../../../common_lib_front/utilities/passCredOrNumber';
import VehicleInfoFormCollection from '../../../components/vehicleInfoForm/vehicleInfoFormCollection';
import style from './registrationRental.module.css';
import {
  EDIT_RENTAL,
  EDIT_RENTAL_RES,
  EDIT_RENTAL_VARS,
  GET_RENATLS,
  GET_RENATLS_RES,
  GET_RENATLS_VARS,
  GET_VEHICLE,
  GET_VEHICLE_RES,
  GET_VEHICLE_VARS,
} from './registrationRentalRequests';
import { useIsPassComplete } from './useIsPassComplete';

export default function RegistrationRental(): ReactElement {
  const [vehicles, setVehicles] = useState<Array<PassInfo>>([]);
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const { t } = useTranslation();
  const { featuresConfig } = useContext(CommunityContext);
  const isPassComplete = useIsPassComplete();

  useQuery<GET_RENATLS_RES, GET_RENATLS_VARS>(GET_RENATLS, {
    variables: {
      status: 'incomplete-rental-car',
    },
    fetchPolicy: 'network-only',
    onError: e => {
      console.error(e);
    },
    onCompleted: d => {
      if (d.getPassesByStatus.data && d.getPassesByStatus.data.length > 0) {
        const res = d.getPassesByStatus.data.map(elem => newPassInfo(elem));
        Promise.all(
          res.map((p: PassInfo, idx: number) =>
            backendClient
              .query<GET_VEHICLE_RES, GET_VEHICLE_VARS>({
                query: GET_VEHICLE,
                fetchPolicy: 'network-only',
                variables: { passId: p.passId },
              })
              .then(v => {
                res[idx].vehicle = newVehicleInfo(
                  v.data.getVehicle.data as Partial<VehicleInfo>,
                );
              }),
          ),
        ).then(() => setVehicles(res));
      }
    },
  });

  const [doEdit] = useMutation<EDIT_RENTAL_RES, EDIT_RENTAL_VARS>(EDIT_RENTAL, {
    onError: e => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: d => {
      if (d.editVehicle.success) {
        setAlert('Changes saved successfully!');
        setAlertColor('green');
      } else {
        setAlert(d.editVehicle.error);
        setAlertColor('red');
      }
    },
  });

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <div className={`${style.subHeader} mainColor `}>
          <div className={`${style.circle} white `} />
          <h1 className={`${style.title} textColorWhite `}>
            {t('Register a Rental Car')}
          </h1>
        </div>
        <div className={style.formBox}>
          {vehicles.length ? (
            <>
              <div className={`${style.alert}`}>
                <GenericAlert
                  color="red"
                  title={`${t('You have')} ${vehicles.length} ${t('incomplete passes')}`}
                />
              </div>
              <GenericAlert color={alertColor} title={alert} hidden={!alert} />
              <VehicleInfoFormCollection
                data={vehicles}
                setData={setVehicles}
                submitHandler={() =>
                  Promise.all(
                    vehicles.map((p: PassInfo) =>
                      doEdit({
                        variables: {
                          vehicleId: p.vehicle.vehicleId,
                          newVehicleInfo: {
                            make: p.vehicle.make,
                            vehicleModel: p.vehicle.vehicleModel,
                            type: p.vehicle.type,
                            color: p.vehicle.color,
                            licensePlate: p.vehicle.licensePlate,
                            licensePlateState: p.vehicle.licensePlateState,
                            fleetNumber: p.vehicle.fleetNumber,
                            isRental: p.vehicle.isRental,
                            primaryDriverName: p.vehicle.primaryDriverName,
                          },
                          passId: p.passId,
                          passInfoId: p.passInfoId,
                          status: isPassComplete(p)
                            ? 'inactive'
                            : 'incomplete-rental-car',
                          // p.vehicle.color.length > 1 &&
                          // p.vehicle.make.length > 1 &&
                          // p.vehicle.plateNumber.length > 1 &&
                          // p.vehicle.type.length > 1
                        },
                      }),
                    ),
                  )
                }
                allowAdd={false}
                allowRemove={false}
                showDriverForm={false}
                formTitle={data => passCredOrNumber(data)}
                showIsRental={false}
                showViewBtn
                showMyPassesLink
              />
            </>
          ) : (
            <div className={style.notBuyPass}>
              {t('You have no rental vehicles to register')}
              <br />
              <br />
              <Link
                to="/guest/registration/"
                className={`${style.here} textColorMain `}
                hidden={true} // make available only if a community allows guest self registration
              >
                {t('Click here to purchase a pass')}
              </Link>
              {/* {t('If you have not yet purchased a pass, please click')}
                  <Link to="/guest/registration/" className={`${style.here}
                   textColorMain `}>{t('Here')}</Link> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
