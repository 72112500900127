import { gql } from '@apollo/client';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';

export const REGISTER_VEHICLE = gql`
  mutation SaveVehicle(
    $passId: String!
    $passInfoId: String!
    $vehicleInfo: ActuallyInputTypeVehicleInput!
    $propertySlug: String!
  ) {
    registerVehicleWithoutContext(
      passId: $passId
      passInfoId: $passInfoId
      vehicleInfo: $vehicleInfo
      propertySlug: $propertySlug
    ) {
      success
      error
    }
  }
`;

type editPassData = {
  color?: string;
  type?: string;
  licensePlate?: string;
};

export type REGISTER_VEHICLE_VARS = {
  passId: string;
  passInfoId: string;
  vehicleInfo: Partial<editPassData>;
  propertySlug: string;
};

export type REGISTER_VEHICLE_RES = {
  registerVehicle: backendResponse<undefined>;
};

export function mutateWrapperForVehiclePass(
  data: REGISTER_VEHICLE_VARS,
): Promise<REGISTER_VEHICLE_RES> {
  return backendClient
    .mutate<REGISTER_VEHICLE_RES, REGISTER_VEHICLE_VARS>({
      mutation: REGISTER_VEHICLE,
      variables: data,
    })
    .then((res: any) => res.data);
}
