import React, {
  Dispatch,
  SetStateAction,
  ReactElement,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as DateSelectIcon } from '../../assets/dateSelect.svg';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import PassInfo from '../../common_lib_front/types/passInfo';
import { VehicleInfoConfig } from '../../common_lib_front/types/vehicleInfo';
import {
  addDays,
  convertLocalToUTCDate,
  formatDayWithDayName,
} from '../../common_lib_front/utilities/formatDate';
import useVehicleConfigs from '../../utils/useVehicleConfigs';
import DatePicker from '../datePicker/datePicker';
import PopUpSmall from '../popUp/popUpSmall';
import VehicleTypeSelect from '../vehicleTypeSelect/vehicleTypeSelect';
import DriverInfoForm from './driverInfoForm';
import style from './vehicleInfoForm.module.css';

type VehicleInfoFormProps = {
  data: PassInfo;
  idx: number;
  title: string;
  description?: string;
  removeHandler: () => void;
  updateHandler: (obj: any) => void;
  allowRemove?: boolean;
  showDriverForm?: boolean;
  showIsRental?: boolean;
  showViewBtn?: boolean;
  config?: VehicleInfoConfig;
  allowEditDates?: boolean; // If invited by host, not allowed to edit dates
  guestName?: string;
  hideDates?: boolean;
  setHideSubmit?: Dispatch<SetStateAction<boolean>>;
};

const defaultConfig: VehicleInfoConfig = {
  make: 'required',
  vehicleModel: 'required',
  type: 'required',
  color: 'required',
  licensePlate: 'required',
  fleetNumber: 'required',
  isRental: 'required',
};

export const VehicleInfoForm = (props: VehicleInfoFormProps): ReactElement => {
  const [open, setOpen] = useState(true);
  const [popUpOpen, setPopUpOpen] = useState<boolean>(false);
  const [count, setCount] = useState(0);
  const { communityId } = useContext(CommunityContext);
  const {
    idx,
    data,
    title,
    description,
    allowRemove,
    showDriverForm,
    showViewBtn,
    config,
    allowEditDates,
    updateHandler,
    guestName,
    hideDates,
    setHideSubmit,
  } = props;
  const { t } = useTranslation();

  const { passInfoDatas } = useVehicleConfigs();

  const passInfo = passInfoDatas.find(item => item.passInfoId === data.passInfoId);

  // =========== date ranges ============
  // Max number of the date range user can select
  const rangeMaxLength = passInfo?.durationInfo?.duration1 || 1;

  const startDate = useMemo(
    () => (data.startDate ? new Date(data.startDate) : null),
    [data],
  );
  const endDate = useMemo(() => (data.endDate ? new Date(data.endDate) : null), [data]);

  const setStartDate = (d: Date) =>
    updateHandler({
      startDate: d.toString(),
    });
  const setEndDate = useCallback(
    (d: Date) =>
      updateHandler({
        endDate: d.toString(),
      }),
    [updateHandler],
  );

  const maxDate = useMemo(
    () => addDays(startDate as Date, rangeMaxLength),
    [startDate, rangeMaxLength],
  );

  const allRequiredData = useMemo(
    () =>
      Object.keys(config || {})
        .filter(key => config?.[key] === 'required')
        .every(key => !!data.vehicle[key]),
    // bad practice, but easier that refactor. Data is not uniqe by identifyer
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config, ...Object.values(data), ...Object.values(data.vehicle)],
  );
  useEffect(() => {
    if (data.vehicle.isRental && allRequiredData) {
      updateHandler({
        vehicle: { isRental: false },
        status: 'incomplete-rental-car',
      });
    }
  }, [allRequiredData, data.vehicle.isRental, updateHandler]);

  useEffect(() => {
    if (!data?.startDate && !data?.endDate && !allowEditDates) {
      const start = new Date();
      const end = new Date(start);

      end.setDate(end.getDate() + rangeMaxLength);

      updateHandler({
        startDate: start.toString(),
        endDate: end.toString(),
      });
    }
    if (setHideSubmit) {
      setHideSubmit(false);
    }
  }, [allowEditDates, data, updateHandler, rangeMaxLength, setHideSubmit]);

  useEffect(() => {
    if (startDate && endDate && allowEditDates) {
      // Fixing if endDate less than startDate
      if (startDate > endDate) {
        setEndDate(startDate);
      }
      // Fixing endDate greater than maxDate
      if (endDate > maxDate) {
        setEndDate(maxDate);
      }
    }
  }, [allowEditDates, startDate, endDate, maxDate, setEndDate]);

  const DateInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <button type="button" className={style.dateInput} onClick={onClick} ref={ref}>
      <span>
        {value ? formatDayWithDayName(convertLocalToUTCDate(new Date(value))) : null}
      </span>
      <DateSelectIcon />
    </button>
  ));
  DateInput.displayName = 'DateInput';

  // =========== end date ranges ============

  if (popUpOpen) {
    return (
      <PopUpSmall
        title="Important Notice"
        isOpen={popUpOpen}
        close={() => setPopUpOpen(false)}
        showTitleMiddle
      >
        <div className={style.noticeBox}>
          <p className={style.popUpContent}>
            Prior to entering the community, you must return to this system and enter in
            your Rental Vehicle information. Your pass will not be functional until this
            has been completed.
          </p>
          <div className={style.noticeBtn}>
            <GenericButton
              title="Continue"
              color="blue"
              size="large"
              clickHandler={() => setPopUpOpen(false)}
            />
          </div>
        </div>
      </PopUpSmall>
    );
  }
  console.log('data', data);
  return (
    <div className={style.infoFormBox}>
      <div className={`${style.header} superDarkGrey `} title={description}>
        <div className={style.btnDown}>
          <GenericButton
            color="transparent"
            shape="drop-down"
            title={open ? '<' : '>'}
            clickHandler={() => setOpen(!open)}
          />
        </div>
        <strong className={`${style.header} textColorWhite `}>{title}</strong>
        {showViewBtn ? (
          <div className={style.view}>
            <Link to={{ pathname: '/guest/pass-details', state: { data } }}>
              <GenericButton shape="view" title={t('View')} />
            </Link>
          </div>
        ) : null}
        {allowRemove ? (
          <div className={style.btnRemove}>
            <GenericButton
              color="transparent"
              title={t('Remove')}
              clickHandler={() => props.removeHandler()}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      {open ? (
        <div className={style.inputBox}>
          {config?.completeLater !== 'disabled' && (
            <div className={style.checkBox}>
              <InputField
                checkBox
                htmlFor={`rental-check-inpt-${idx}`}
                labelTitle={t('Vehicle Info Unknown (Rental Vehicle)')}
                inputType="checkbox"
                checked={data.vehicle?.isRental}
                disabled={allRequiredData}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateHandler({
                    vehicle: { isRental: e.target.checked || false },
                    status: e.target.checked ? 'incomplete-rental-car' : 'incomplete',
                  });
                  setCount(count + 1);
                  if (data.vehicle?.isRental && count === 0) {
                    setPopUpOpen(true);
                  }
                }}
              />
              {data.vehicle?.isRental ? (
                <p className={`${style.alert} backgroungColor textColorDark `}>
                  {t('rental_prompt')}
                </p>
              ) : (
                <p className={`${style.alert} backgroungColor textColorDark `}>
                  Check this box if this is a <b>Rental Vehicle</b> and you do not have
                  vehicle information.
                </p>
              )}
            </div>
          )}

          {/* {config?.name !== 'disabled' ? (
            <div className={style.inputShort}>
              <InputField
                closedInput
                htmlFor={`name-inpt-${idx}`}
                labelTitle={t('Guest Name')}
                inputType="text"
                inputValue={data.vehicle.name}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateHandler({ vehicle: { name: e.target.value } });
                }}
                required={config?.name === 'required' && !data.vehicle?.isRental}
              />
            </div>
          ) : null} */}
          {config?.primaryDriverName !== 'disabled' ? (
            <div className={style.inputShort}>
              <InputField
                closedInput
                htmlFor={`primary-driver-name-inpt-${idx}`}
                labelTitle={t('Name')}
                inputType="text"
                inputValue={data.vehicle.primaryDriverName}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateHandler({ vehicle: { primaryDriverName: e.target.value } });
                }}
                required={
                  config?.primaryDriverName === 'required' && !data.vehicle?.isRental
                }
              />
            </div>
          ) : null}
          {config?.make !== 'disabled' ? (
            <div className={style.inputShort}>
              <InputField
                closedInput
                htmlFor={`make-inpt-${idx}`}
                labelTitle={t('Make')}
                inputType="text"
                inputValue={data.vehicle.make}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateHandler({ vehicle: { make: e.target.value } });
                }}
                required={config?.make === 'required' && !data.vehicle?.isRental}
              />
            </div>
          ) : null}
          {config?.vehicleModel && config.vehicleModel !== 'disabled' ? (
            <div className={style.inputShort}>
              <InputField
                closedInput
                htmlFor={`model-inpt-${idx}`}
                labelTitle={t('Model')}
                inputType="text"
                inputValue={data.vehicle.vehicleModel}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateHandler({ vehicle: { vehicleModel: e.target.value } });
                }}
                required={config?.vehicleModel === 'required' && !data.vehicle?.isRental}
              />
            </div>
          ) : null}

          {config?.type && config.type !== 'disabled' ? (
            <div className={style.inputShort}>
              <VehicleTypeSelect
                htmlFor={`type-inpt-${idx}`}
                labelTitle={t('Type')}
                inputValue={data.vehicle.type}
                changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  updateHandler({ vehicle: { type: e.target.value } });
                }}
                required={config?.type === 'required' && !data.vehicle?.isRental}
              />
            </div>
          ) : null}
          {config?.color && config.color !== 'disabled' ? (
            <div className={style.inputShort}>
              <InputField
                closedInput
                htmlFor={`color-inpt-${idx}`}
                labelTitle={t('Color')}
                inputValue={data.vehicle.color}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateHandler({ vehicle: { color: e.target.value } });
                }}
                required={config?.color === 'required' && !data.vehicle?.isRental}
              />
            </div>
          ) : null}
          {config?.licensePlate && config.licensePlate !== 'disabled' ? (
            <div className={style.inputShort}>
              <InputField
                closedInput
                htmlFor={`plate-inpt-${idx}`}
                labelTitle={t('License Plate #')}
                inputValue={data.vehicle.licensePlate}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateHandler({ vehicle: { licensePlate: e.target.value } });
                }}
                required={config?.licensePlate === 'required' && !data.vehicle?.isRental}
              />
            </div>
          ) : null}
          {config?.fleetNumber && config.fleetNumber !== 'disabled' ? (
            <div className={style.inputShort}>
              <InputField
                closedInput
                htmlFor={`fleet-inpt-${idx}`}
                labelTitle={t('Fleet #')}
                inputValue={data.vehicle.fleetNumber}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateHandler({ vehicle: { fleetNumber: e.target.value } });
                }}
                required={config?.fleetNumber === 'required' && !data.vehicle?.isRental}
              />
            </div>
          ) : null}
          {config?.licensePlateState !== 'disabled' ? (
            <div className={style.inputShort}>
              <InputField
                closedInput
                htmlFor={`license-plate-state-inpt-${idx}`}
                labelTitle={t('License Plate State')}
                inputType="text"
                inputValue={data.vehicle.licensePlateState}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateHandler({ vehicle: { licensePlateState: e.target.value } });
                }}
                required={
                  config?.licensePlateState === 'required' && !data.vehicle?.isRental
                }
              />
            </div>
          ) : null}
          {config?.name !== 'disabled' ? (
            <div className={style.inputShort}>
              <InputField
                closedInput
                htmlFor={`primary-driver-name-inpt-${idx}`}
                labelTitle={t('Name')}
                inputType="text"
                inputValue={data.vehicle.primaryDriverName}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateHandler({ vehicle: { primaryDriverName: e.target.value } });
                }}
                required={
                  config?.primaryDriverName === 'required' && !data.vehicle?.isRental
                }
              />
            </div>
          ) : null}
          {config?.licensePrimaryDriver !== 'disabled' ? (
            <div className={style.inputShort}>
              <InputField
                closedInput
                htmlFor={`license-primary-driver-inpt-${idx}`}
                labelTitle={t("Primary Driver's License #")}
                inputType="text"
                inputValue={data.vehicle.licensePrimaryDriver}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateHandler({
                    vehicle: { licensePrimaryDriver: e.target.value },
                  });
                }}
                required={
                  config?.licensePrimaryDriver === 'required' && !data.vehicle?.isRental
                }
              />
            </div>
          ) : null}
          {!hideDates && (
            <div className={style.tableContainer}>
              <div className={style.inputShort}>
                <div className={`${style.dateBox} textColorSuperDark `}>
                  {t('Start Date')}
                  <DatePicker
                    date={startDate}
                    setDate={setStartDate}
                    minDate={new Date()}
                    customInput={<DateInput />}
                    disabled={!allowEditDates}
                  />
                </div>
              </div>
              <div className={style.inputShort}>
                <div className={`${style.dateBox} textColorSuperDark `}>
                  {t('Valid Through')}
                  <DatePicker
                    date={endDate}
                    setDate={setEndDate}
                    minDate={startDate}
                    maxDate={maxDate}
                    customInput={<DateInput />}
                    disabled={!allowEditDates}
                  />
                </div>
              </div>
            </div>
          )}
          {showDriverForm ? <DriverInfoForm /> : <></>}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

VehicleInfoForm.defaultProps = {
  description: undefined,
  allowRemove: true,
  showDriverForm: false,
  showIsRental: true,
  showViewBtn: false,
  config: defaultConfig,
  allowEditDates: false,
};

export default VehicleInfoForm;
