import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AlertIcon } from '../../../assets/alert.svg';
import style from './NoVehicleAlert.module.css';
export const NoVehicleAlert = (props: {
  color?: 'red' | 'green' | 'yellow';
  title?: string;
  linkTo: string;
  className?: string;
}): ReactElement => {
  const { color, title, linkTo, className } = props;

  const containerStyle = {
    backgroundColor: '',
    border: '',
    color: '',
  };

  switch (color) {
    case 'red':
      containerStyle.backgroundColor = '#FFF2F0';
      containerStyle.border = '1px solid #d9363e';
      containerStyle.color = '#d9363e';
      break;
    case 'green':
      containerStyle.backgroundColor = '#F6FFED';
      containerStyle.border = '1px solid #73d13d';
      containerStyle.color = '#389e0d';
      break;
    case 'yellow':
      containerStyle.backgroundColor = '#FFFFE6';
      containerStyle.border = '1px solid #e6e600';
      containerStyle.color = '#b3b300';
      break;
    default:
      containerStyle.backgroundColor = 'var(--alertColor)';
      containerStyle.border = '1px solid #d9363e';
      containerStyle.color = '#d9363e';
      break;
  }
  return (
    <div style={containerStyle} className={`${style.alertLine}  ${className}`}>
      <AlertIcon className={style.icon} />
      <h5 className={style.title}>{title}</h5>
      <Link to={linkTo} className={style.linkBtn}>Update</Link>
    </div>
  );
};
NoVehicleAlert.defaultProps = {
  color: 'none',
  children: '',
  title: '',
  hidden: false,
  className: '',
};

export default NoVehicleAlert;
