import { useQuery } from '@apollo/client';
import { ReactElement, createContext, useEffect, useMemo } from 'react';
import store, { isLogedIn } from '../utilities/store';
import { getAndSetToken, logout } from '../utilities/thunks';
import './color.css';
import {
  CommunityType,
  GET_COMMUNITY_DATA,
  GET_COMMUNITY_DATA_RES,
  GET_COMMUNITY_DATA_VARS,
} from './communitiesRequests';
import { getCommunityId } from './getCommunityId';

type CommunityAssets = {
  logo: string;
  passLogo: string;
  bigLogo: string;
  subLogo: string;
};

const defaultAssets = {
  logo: '',
  passLogo: '',
  bigLogo: '',
  subLogo: '',
};

const defaultCommunity: CommunityType = {
  communityId: '',
  name: 'Community Does Not Exist',
};

interface InlineStyles {
  [key: string]: string | undefined;
}

interface CommunityContextType extends CommunityType, CommunityAssets {}
// context object default
export const CommunityContext = createContext<CommunityContextType>({
  ...defaultCommunity,
  ...defaultAssets,
});

// Context provider component
export function CommunityContextProvider(props: {
  children: ReactElement | Array<ReactElement> | undefined;
}): ReactElement {
  const { children } = props;
  // const [community, setCommunity] = useState<CommunityType>(defaultCommunity);
  const storeCommunityId = store.getState()?.community?.communityId;
  const communityId = getCommunityId();
  // fetch community data
  const { data: communityData, loading: communityLoading } = useQuery<
    GET_COMMUNITY_DATA_RES,
    GET_COMMUNITY_DATA_VARS
  >(GET_COMMUNITY_DATA, {
    variables: {
      communityId,
      __cfg_no_token: true,
    },
  });
  const fetchedCommunity = communityData?.getCommunityById.data?.[0];
  /**
   * Automatically log out the user if their community is changed
   */
  useEffect(() => {
    if (
      fetchedCommunity?.communityId &&
      isLogedIn() &&
      storeCommunityId !== fetchedCommunity.communityId
    ) {
      store.dispatch(logout);
    }
  }, [fetchedCommunity?.communityId, storeCommunityId]);

  /**
   * hook to set css color class and assets
   */
  useEffect(() => {
    document.body.className = fetchedCommunity?.communityId || '';
    return () => {
      // cleanup function
      document.body.className = '';
    };
  }, [fetchedCommunity?.communityId]);

  /**
   * hook to update token
   */
  useEffect(() => {
    if (fetchedCommunity?.communityId && !isLogedIn()) {
      store.dispatch(getAndSetToken(fetchedCommunity?.communityId));
    }
  }, [fetchedCommunity?.communityId]);

  /**
   * memoize context value
   */
  const communityContextValue = useMemo(() => {
    let assets: CommunityAssets = defaultAssets;
    if (fetchedCommunity?.communityId) {
      try {
        const communityAssets = require(`./${fetchedCommunity?.communityId}/index.ts`) as CommunityAssets; // eslint-disable-line
        assets = {
          ...assets,
          ...communityAssets,
        };
      } catch (error) {
        // console.log(error);
        console.warn('Could not import community assets statically'); // eslint-disable-line no-console
        assets = defaultAssets;
      } finally {
        // include raw and prepared values for safety
        if (fetchedCommunity?.styling?.headerImage) {
          defaultAssets.logo = fetchedCommunity?.styling?.headerImage; // logo set
        }
        if (fetchedCommunity?.styling?.backgroundImage) {
          assets.bigLogo = fetchedCommunity?.styling?.backgroundImage; // big logo set
        }
      }
    }
    return {
      ...(fetchedCommunity || defaultCommunity),
      ...assets,
    };
  }, [fetchedCommunity]);

  const inlineStyles: InlineStyles = useMemo(() => {
    if (fetchedCommunity?.styling) {
      return {
        '--mainColor': fetchedCommunity?.styling?.mainColor,
        '--secondaryColor': fetchedCommunity?.styling?.secondaryColor,
        '--thirdColor': fetchedCommunity?.styling?.thirdColor,
        '--hoverColor': fetchedCommunity?.styling?.hoverColor,
        '--superDarkGrey': fetchedCommunity?.styling?.superDarkGrey,
        '--darkGrey': fetchedCommunity?.styling?.darkGrey,
        '--mediumGrey': fetchedCommunity?.styling?.mediumGrey,
        '--lightGrey': fetchedCommunity?.styling?.lightGrey,
        '--specialGrey': fetchedCommunity?.styling?.specialGrey,
        '--sucessColor': fetchedCommunity?.styling?.sucessColor,
        '--alertColor': fetchedCommunity?.styling?.alertColor,
        '--backgroungColor': fetchedCommunity?.styling?.backgroundColor,
        '--green': fetchedCommunity?.styling?.green,
        '--red': fetchedCommunity?.styling?.red,
        '--gradient': fetchedCommunity?.styling?.gradient,
      };
    }
    return {};
  }, [fetchedCommunity]);

  // return context provider and children or a helper to select community
  return (
    <CommunityContext.Provider value={communityContextValue}>
      {fetchedCommunity?.communityId ? (
        <div style={inlineStyles}>
          {
            children // render children if mounted, ie community id is not empty string
          }
        </div>
      ) : communityLoading ? (
        <></>
      ) : (
        // render community selection helper
        <div>Community does not exist</div>
      )}
    </CommunityContext.Provider>
  );
}
