import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCompanyVehicles } from '../../hooks/useCompanyVehicles';
import VehicleInfo, { newVehicleInfo } from '../../types/vehicleInfo';
import SelectTable from '../selectTable/selectTable';

type VehicleSelectProps = {
  id: string;
  vehicle?: VehicleInfo | null;
  setVehicle: (val: VehicleInfo | undefined) => void;
  required?: boolean;
};

export default function VehicleSelect(props: VehicleSelectProps): React.ReactElement {
  const { id, vehicle, setVehicle, required } = props;
  const { data: vehiclesData, refetch } = useCompanyVehicles();
  const location = useLocation();

  useEffect(() => {
    refetch();
  }, [location, refetch]);

  return (
    <SelectTable<VehicleInfo>
      id={id}
      required={required}
      displayVal={v => (v ? `${v?.plateNumber} - ${v?.color} ${v?.make}` : '')}
      value={vehicle || undefined}
      setValue={setVehicle}
      preventClear
      validityTest={obj => !!(obj.plateNumber || obj.color || obj.make)}
      data={
        vehiclesData?.getCompanyVehicles.data?.map(d =>
          newVehicleInfo(d as unknown as Partial<VehicleInfo>),
        ) || []
      }
      headers={[
        {
          label: 'Make',
          field: 'make',
        },
        {
          label: 'License Plate',
          field: 'plateNumber',
        },
        {
          label: 'Color',
          field: 'color',
        },
      ]}
      topHead={
        <div>
          <Link to="/resident/edit-profile/add-edit-vehicle">Add Vehicle</Link>
        </div>
      }
    />
  );
}
VehicleSelect.defaultProps = {
  vehicle: undefined,
  required: undefined,
};
