import { TextField } from '@mui/material';
import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import { ChipsInput } from '../../../common_lib_front/components/ChipsInput/ChipsInput';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import doValidate from '../../../common_lib_front/components/inviteGuestForm/useRentalValidation';
import PhoneInputWrapper from '../../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import useGetMyGuests from '../../../common_lib_front/hooks/useMyGuest';
import useOrderPrice, { PassPrice } from '../../../common_lib_front/hooks/useOrderPrice';
import useProperty from '../../../common_lib_front/hooks/useProperty';
import { newVehicleInfo } from '../../../common_lib_front/types/vehicleInfo';
import { useInviteGuest } from '../../../hooks/useInviteGuest';
import useVehicleConfigs, { PassInfoData } from '../../../utils/useVehicleConfigs';
import style from './getPasses.module.css';
import { mapPassIdsToVehicles } from './mapPassIdsToVehicles';
import { mutateWrapperForVehiclePass } from './mutateWrapperForVehiclePass';
import PassOptions from './passInfoComponent';
import { GetPassesData, VehiclePass } from './types';
import { PassOptionErrorsType, validateVehicleData } from './validateVehicleData';

// import VehiclePassForm from './vehiclePassForm';

const errorMessages = {
  firstName: 'First Name is required.',
  lastName: 'Last Name is required.',
  email: 'Email or Phone number is required.',
  phoneNumber: 'Email or Phone number is required.',
  startDate: 'Check-In & Check-Out dates are required.',
  endDate: 'Check-In & Check-Out dates are required.',
};

type ErrorsType = {
  firstName?: boolean;
  lastName?: boolean;
  email?: boolean;
  phoneNumber?: boolean;
  startDate?: boolean;
  endDate?: boolean;
};

export default function GetPasses(): ReactElement {
  const history = useHistory();
  const { communityId, featuresConfig } = useContext(CommunityContext);
  const { propertySlug } = useParams<{ propertySlug: string }>();
  const { registrationId } = useParams<{ registrationId: string }>();
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const [smsAlert, setSmsAlert] = useState<boolean>(false);
  const [promotionalAlert, setPromotionalAlert] = useState<boolean>(false);
  const { inviteGuests, data, loading: inviteLoading } = useInviteGuest();
  const [redirectPath, setRedirectPath] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { data: myGuestDetails, error: guestDetailsError } = useGetMyGuests({
    registrationIds: registrationId ? [registrationId] : [],
    propertySlug: propertySlug,
  });

  const today = new Date().toISOString().split('T')[0];
  const targetDivRef = useRef<HTMLDivElement | null>(null);

  const [getPassesData, setGetPassesData] = useState<GetPassesData>({
    firstName: '',
    email: '',
    phoneNumber: '',
    startDate: '',
    endDate: '',
    lastName: '',
    guestNames: [],
  });

  const [guestInfo, setGuestInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  });
  const [rentalInfo, setRentalInfo] = useState({
    address: '',
    arrivalDate: '',
    departureDate: '',
    guestNames: [] as string[],
  });

  const [passes, setPasses] = useState<any>([]);
  const [passInfoId, setPassInfoId] = useState<PassInfoData[]>([]);
  const [days, setDays] = useState<number>(0);
  const [vehiclePassesByType, setVehiclePassesByType] = useState<{
    [passInfoId: string]: VehiclePass[];
  }>({});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errors, setErrors] = useState<ErrorsType>({});
  const [passOptionErrors, setPassOptionErrors] = useState<PassOptionErrorsType>({});

  useEffect(() => {
    if (myGuestDetails?.length) {
      setGuestInfo({
        firstName: myGuestDetails[0].guestInfo.firstName,
        lastName: myGuestDetails[0].guestInfo.lastName,
        email: myGuestDetails[0].guestInfo.email,
        phoneNumber: myGuestDetails[0].guestInfo.phoneNumber,
      });

      setGetPassesData({
        firstName: myGuestDetails[0].guestInfo.firstName,
        email: myGuestDetails[0].guestInfo.email,
        phoneNumber: myGuestDetails[0].guestInfo.phoneNumber,
        startDate: myGuestDetails[0].communityRental.arrivalDate,
        endDate: myGuestDetails[0].communityRental.departureDate,
        lastName: myGuestDetails[0].guestInfo.lastName,
        guestNames: myGuestDetails[0]?.communityRental?.guestNames || [],
      });

      myGuestDetails[0].passes?.forEach(pass => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        addVehiclePass(pass.passInfoId);
      });
    }
  }, [myGuestDetails]);

  useEffect(() => {
    if (getPassesData.startDate && getPassesData.endDate) {
      const startDate = new Date(getPassesData.startDate);
      const endDate = new Date(getPassesData.endDate);
      const timeDiff = endDate.getTime() - startDate.getTime();
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      setDays(diffDays);
    }
  }, [getPassesData]);

  const {
    data: propertyDetail,
    loading: queryLoading,
    error: propertyError,
  } = useProperty({ propertySlug: propertySlug || '' });
  const { passInfoDatas, vehicleConfig } = useVehicleConfigs();

  useEffect(() => {
    setVehiclePassesByType(prevState => {
      const chargeForFullCapacityPassInfos = passInfoDatas.filter(
        p => p.priceInfo?.chargeForFullCapacity && p.portal === 'guest',
      );
      const copy = {
        ...prevState,
      };
      chargeForFullCapacityPassInfos.forEach(cffcPassInfo => {
        if (!prevState[cffcPassInfo.passInfoId]?.length) {
          copy[cffcPassInfo.passInfoId] = [newVehicleInfo({}) as unknown as VehiclePass];
        }
      });
      return copy;
    });
  }, [passInfoDatas, setVehiclePassesByType]);

  const handleChange =
    (field: keyof GetPassesData) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setGetPassesData({
        ...getPassesData,
        [field]: e.target.value,
      });
    };

  const handlePhoneChange = (phone: string) => {
    setGetPassesData({
      ...getPassesData,
      phoneNumber: phone,
    });
  };

  const handleVehicleChange = (
    passInfoId: string,
    index: number,
    field: keyof VehiclePass,
    value: string,
  ) => {
    setVehiclePassesByType(prevState => ({
      ...prevState,
      [passInfoId]:
        prevState[passInfoId]?.map((vehiclePass, i) =>
          i === index ? { ...vehiclePass, [field]: value } : vehiclePass,
        ) || [],
    }));
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleCheckboxChange = (passInfoId: string, index: number) => {
    setVehiclePassesByType(prevState => {
      const updatedPasses =
        prevState[passInfoId]?.map((vehiclePass, i) => {
          if (i === index) {
            const updatedPass = {
              ...vehiclePass,
              dontKnowLicense: !vehiclePass.dontKnowLicense,
            };
            if (updatedPass.dontKnowLicense) {
              updatedPass.licensePlate = '';
            }
            return updatedPass;
          }
          return vehiclePass;
        }) || [];

      return {
        ...prevState,
        [passInfoId]: updatedPasses,
      };
    });
  };

  const addVehiclePass = (passInfoId: string) => {
    setVehiclePassesByType(prevState => ({
      ...prevState,
      [passInfoId]: [
        ...(prevState[passInfoId] || []),
        {
          make: '',
          type: '',
          color: '',
          licensePlate: '',
          dontKnowLicense: false,
          primaryDriverName: '',
          vehicleModel: '',
          fleetNumber: '',
          licensePlateState: '',
          licensePrimaryDriver: '',
          startDate: '',
          endDate: '',
        },
      ],
    }));

    if (getPassesData.startDate && getPassesData.endDate) {
      setPasses((prevState: any) => [
        ...prevState,
        {
          passInfoId,
          startDate: vehiclePassesByType.startDate,
          endDate: vehiclePassesByType.endDate,
        },
      ]);
    }

    console.log({ vehiclePassesByType });
  };

  const removeVehiclePass = (passInfoId: string) => {
    setVehiclePassesByType(prevState => {
      const updatedVehiclePasses = (prevState[passInfoId] || []).slice(0, -1);

      setPasses((prevPasses: any) => {
        const updatedPasses = prevPasses.filter(
          (p: any) => p.passInfoId !== passInfoId || updatedVehiclePasses.length > 0,
        );

        return updatedPasses;
      });

      return {
        ...prevState,
        [passInfoId]: updatedVehiclePasses,
      };
    });
  };

  // Create sample passes for each passInfoId
  const createSamplePasses = (passInfoIds: PassInfoData[]) => {
    return passInfoIds.map(passInfo => ({
      passInfoId: passInfo.passInfoId,
      startDate: getPassesData.startDate,
      endDate: getPassesData.endDate,
    }));
  };

  const samplePasses = useMemo(
    () => createSamplePasses(passInfoId),
    [passInfoId, getPassesData.startDate, getPassesData.endDate],
  );

  useEffect(() => {
    const passInfoId = passInfoDatas.filter(
      passInfoData => passInfoData.portal === 'guest',
    );
    setPassInfoId(passInfoId);
  }, [passInfoDatas]);

  // useEffect(() => {
  //   const filteredPassInfoIds = passInfoId.filter(passInfoData => {
  //     const duration = passInfoData?.durationInfo?.duration1;
  //     const isMatchingPass = passInfoData.portal === 'guest' &&
  //       (typeof duration === 'undefined' || duration === null || duration >= days);
  //       console.log('filteredPassInfoIds', isMatchingPass);
  //     return isMatchingPass;
  //   });
  //   setPassInfoId(filteredPassInfoIds);
  // }, [passInfoDatas, days]);

  const passInfoFilter = (passInfoData: PassInfoData) => {
    // Check for missing fields or incorrect structure
    const maxDuration = passInfoData!.durationInfo!.duration1!;
    if (passInfoData.portal !== 'guest') {
      return false;
    }

    if (!getPassesData.startDate || !getPassesData.endDate) {
      return true;
    }
    return days <= maxDuration;
  };

  // const calculateTotals = () => {
  //   return passInfoId.filter(passInfoFilter).map(passInfo => {
  //     const vehiclePasses = vehiclePassesByType[passInfo.passInfoId] || [];
  //     const totalCount = vehiclePasses.length;
  //     const passPrice = passInfo.priceInfo?.price || 0;
  //     const totalAmount = totalCount * passPrice;

  //     return {
  //       passType: passInfo.name,
  //       totalCount,
  //       totalAmount,
  //     };
  //   });
  // };

  // const aggregateTotals = () => {
  //   const totals = calculateTotals();

  //   let totalCount = 0;
  //   let totalAmount = 0;

  //   totals.forEach(({ totalCount: count, totalAmount: amount }) => {
  //     totalCount += count;
  //     totalAmount += amount;
  //   });

  //   return {
  //     totalCount,
  //     totalAmount,
  //   };
  // };
  const {
    totalPrice: orderPriceTotalPrice,
    fees: orderPriceFees,
    passes: orderPricePasses,
    passesByType: orderPricePassesByType,
    loading: orderPriceLoading,
  } = useOrderPrice(passes, {
    defaultAddress: propertyDetail?.address,
  });

  useEffect(() => {
    setGuestInfo({
      firstName: getPassesData.firstName,
      lastName: getPassesData.lastName,
      email: getPassesData.email,
      phoneNumber: getPassesData.phoneNumber,
    });

    setRentalInfo({
      address: propertyDetail?.address || '',
      arrivalDate: getPassesData.startDate,
      departureDate: getPassesData.endDate,
      guestNames: getPassesData.guestNames,
    });
  }, [propertyDetail, getPassesData]);

  const isGuestLink =
    propertyDetail?.guestPay && propertyDetail.guestPropertySlug === propertySlug;
  // const totals = calculateTotals();
  // const { totalCount, totalAmount } = aggregateTotals();
  // Validation logic
  const validateForm = () => {
    const { firstName, lastName, email, phoneNumber, startDate, endDate } = getPassesData;
    const errors: ErrorsType = {};

    if (!firstName) {
      errors.firstName = true;
    }
    if (!lastName) {
      errors.lastName = true;
    }
    if (!email && !phoneNumber) {
      errors.email = true;
      errors.phoneNumber = true;
    }
    if (!startDate) {
      errors.startDate = true;
    }
    if (!endDate) {
      errors.endDate = true;
    }
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const updatedPasses = Object.keys(vehiclePassesByType).flatMap(passInfoId => {
      return vehiclePassesByType[passInfoId].map(p => ({
        passInfoId,
        startDate: p.startDate,
        endDate: p.endDate,
      }));
    });

    setPasses(updatedPasses);
  }, [vehiclePassesByType]);

  const handleContinue = async (e: any) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (orderPricePasses?.length === 0) {
      setErrorMessage('Please add at least one pass option.');
      return;
    }

    setLoading(true);

    try {
      const updatedPasses = Object.keys(vehiclePassesByType).flatMap(passInfoId => {
        console.log({ passInfoId });
        return vehiclePassesByType[passInfoId].map(p => ({
          passInfoId,
          startDate: p.startDate,
          endDate: p.endDate,
        }));
      });

      console.log({ updatedPasses });
      setPasses(updatedPasses);
      setErrorMessage('');

      console.log('passes from before mutation', passes);

      const validationResult = validateVehicleData(vehiclePassesByType, vehicleConfig);
      console.log('validationResult----', validationResult);
      if (validationResult.valid && passes.length > 0) {
        console.log('passes from handleContinue', passes);
        await doValidate({
          passesRentals: [
            {
              rentalInfo: {
                address: rentalInfo.address,
                arrivalDate: rentalInfo.arrivalDate,
                departureDate: rentalInfo.departureDate,
              },
              passes: passes.map((pass: any) => ({
                passInfoId: pass.passInfoId,
                endDate: pass.endDate,
                startDate: pass.startDate,
              })),
            },
          ],
        });

        const invites = [
          {
            guestInfo: {
              firstName: guestInfo.firstName,
              lastName: guestInfo.lastName,
              email: guestInfo.email,
              phoneNumber: guestInfo.phoneNumber,
            },
            hostWillPay: true,
            passes: passes.map((pass: any) => ({
              passInfoId: pass.passInfoId,
              endDate: pass.endDate,
              startDate: pass.startDate,
            })),
            rentalInfo: {
              address: rentalInfo.address,
              arrivalDate: rentalInfo.arrivalDate,
              departureDate: rentalInfo.departureDate,
              guestNames: rentalInfo.guestNames,
            },
          },
        ];
        const result = await inviteGuests(invites, propertySlug, true);
        const data = result?.inviteGuests?.data ?? [];
        const addedPasses = data.length > 0 ? data[0].passes ?? [] : [];
        const registrationId =
          data.length > 0 ? data[0].registration?.registrationId ?? '' : '';
        if (addedPasses.length > 0) {
          const formattedPasses = addedPasses.reduce<Record<string, any[]>>(
            (acc, curr) => {
              const { passInfoId } = curr;
              if (passInfoId !== undefined) {
                if (!acc[passInfoId]) {
                  acc[passInfoId] = [];
                }
                acc[passInfoId].push(curr);
              }
              return acc;
            },
            {},
          );

          const passesWithVehicle = mapPassIdsToVehicles(
            formattedPasses,
            vehiclePassesByType,
            propertySlug,
            {
              destination: propertyDetail?.address || '',
              primaryDriverName:
                (getPassesData.firstName || '') + ' ' + (getPassesData.lastName || ''),
            },
          );
          // passesWithVehicle.forEach((passWithVehicle: any) => {
          //   mutateWrapperForVehiclePass(passWithVehicle);
          // });
          await Promise.all(
            passesWithVehicle.map((passWithVehicle: any) =>
              mutateWrapperForVehiclePass(passWithVehicle),
            ),
          );
          const nextPageURL = `/guest/${propertySlug}/${registrationId}/education`;
          scrollToTop();

          history.replace(nextPageURL);
        } else {
          if (result?.inviteGuests?.error === 'Overlapping Reservations') {
            setErrorMessage(
              'The dates you’ve requested already have too many overlapping reservations and have reached their limit. Please contact your host for further questions.',
            );
          } else {
            setErrorMessage(result?.inviteGuests?.error || '');
          }
        }
      } else {
        setPassOptionErrors(validationResult.errors || {});
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('An error occurred while sending invitations.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errorMessage) {
      if (targetDivRef.current) {
        const elementPosition = targetDivRef.current.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - 200; // Adjust scroll position by -100px

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
        // targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [errorMessage]);

  useEffect(() => {
    if (queryLoading || !propertySlug || !passInfoDatas) {
      setLoading(true);
      return;
    }
    if (propertyError) {
      setRedirectPath(`/guest/${propertySlug}/link-disabled`);
      setLoading(false);
    } else {
      if (propertyDetail) {
        if (
          (propertyDetail?.hostPropertySlug == propertySlug &&
            propertyDetail?.hostPay === false) ||
          (propertyDetail?.guestPropertySlug == propertySlug &&
            propertyDetail?.guestPay === false)
        ) {
          setRedirectPath(`/guest/${propertySlug}/link-disabled`);
        }
      }
      setLoading(false);
    }
  }, [propertyDetail, propertySlug, queryLoading, passInfoDatas, propertyError]);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <div className={style.container}>
      <div className={`${style.box} white noShadow`}>
        <form
          className={`${style.passForm}`}
          onSubmit={handleContinue}
          onKeyDown={ev => {
            if (
              ev.key === 'Enter' &&
              ev.target instanceof HTMLInputElement &&
              ev.target.id === 'guest-names-inpt'
            ) {
              ev.preventDefault();
            }
          }}
        >
          <h1 className={`${style.subHeaderTitle} textColorBlack`}>
            Get your access passes.
          </h1>
          <div className={`${style.mainBox}`}>
            <div className={`${style.innerBox}`}>
              <div className={`${style.propertyDetail}`}>
                <div>
                  <h3 className={`${style.propertyHeading}`}>Property Details</h3>
                </div>
                <div className={`${style.propertyAddress}`}>
                  {propertyDetail?.address}, {propertyDetail?.city},{' '}
                  {propertyDetail?.state} {propertyDetail?.zipCode}
                </div>
              </div>
            </div>

            <div className={style.alert}>
              <div className={style.alertBox}>
                <GenericAlert color={alertColor} title={alert} hidden={!alert} />
              </div>
            </div>

            {errorMessage != '' && (
              <div ref={targetDivRef} className={style.errorContainer}>
                <p className={style.errorMessage}>{errorMessage}</p>
              </div>
            )}

            <div className={style.guestDetailBox}>
              <div className={style.row}>
                <div className={style.box__inputField}>
                  <label htmlFor="firstName-inpt" className={style.label}>
                    First Name <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div className={style.inputBox}>
                    <InputField
                      required
                      smallSize
                      htmlFor="firstName-inpt"
                      inputType="text"
                      inputValue={getPassesData.firstName}
                      highlightOnFocus
                      changeHandler={handleChange('firstName')}
                      inputPlaceholder="Add your first name"
                    />
                    {errors.firstName ? (
                      <span style={{ color: 'red' }}>{errorMessages.firstName}</span>
                    ) : null}
                  </div>
                </div>
                <div className={style.box__inputField}>
                  <label htmlFor="lastName-inpt" className={style.label}>
                    Last Name <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div className={style.inputBox}>
                    <InputField
                      required
                      smallSize
                      htmlFor="lastName-inpt"
                      inputType="text"
                      inputValue={getPassesData.lastName}
                      highlightOnFocus
                      changeHandler={handleChange('lastName')}
                      inputPlaceholder="Add your last name"
                    />
                    {errors.lastName ? (
                      <span style={{ color: 'red' }}>{errorMessages.lastName}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={style.row}>
                <div className={style.box__inputField}>
                  <label htmlFor="email-inpt" className={style.label}>
                    Email Address <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div className={style.inputBox}>
                    <InputField
                      required
                      smallSize
                      htmlFor="email-inpt"
                      inputType="email"
                      inputValue={getPassesData.email}
                      highlightOnFocus
                      changeHandler={handleChange('email')}
                      inputPlaceholder="Enter your email address"
                    />
                    {errors.email ? (
                      <span style={{ color: 'red' }}>{errorMessages.email}</span>
                    ) : null}
                  </div>
                </div>
                <div className={style.box__inputField}>
                  <label htmlFor="phone-inpt" className={style.label}>
                    Phone Number <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div className={style.inputBox}>
                    <PhoneInputWrapper
                      value={getPassesData.phoneNumber}
                      changeHandler={handlePhoneChange}
                      smallStandardInput
                      required={true}
                      placeHolder="Enter your phone number"
                    />
                    {errors.phoneNumber ? (
                      <span style={{ color: 'red' }}>{errorMessages.phoneNumber}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={style.row}>
                <div className={style.box__inputField}>
                  <label htmlFor="check-in-inpt" className={style.label}>
                    Check-in Date <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div className={style.inputBox}>
                    <InputField
                      required
                      smallSize
                      htmlFor="startDate-inpt"
                      inputType="date"
                      inputValue={getPassesData.startDate}
                      highlightOnFocus
                      changeHandler={handleChange('startDate')}
                      inputPlaceholder="Choose the date"
                      min={today}
                    />
                    {errors.startDate ? (
                      <span style={{ color: 'red' }}>{errorMessages.startDate}</span>
                    ) : null}
                  </div>
                </div>
                <div className={style.box__inputField}>
                  <label htmlFor="check-out-inpt" className={style.label}>
                    Check-out Date <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div className={style.inputBox}>
                    <InputField
                      required
                      smallSize
                      htmlFor="endDate-inpt"
                      inputType="date"
                      inputValue={getPassesData.endDate}
                      highlightOnFocus
                      changeHandler={handleChange('endDate')}
                      inputPlaceholder="Choose the date"
                      min={getPassesData.startDate}
                    />
                    {errors.endDate ? (
                      <span style={{ color: 'red' }}>{errorMessages.endDate}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              {Boolean(featuresConfig?.guest?.guestNames) && (
                <div className={style.row}>
                  <div className={style.box__inputField}>
                    <label htmlFor="guest-names-inpt" className={style.label}>
                      Guest Names <span style={{ color: 'red' }}>*</span>
                    </label>
                    <ChipsInput
                      value={getPassesData.guestNames}
                      containerProps={{
                        className: style.inputBox,
                      }}
                      inputProps={{
                        id: 'guest-names-inpt',
                        placeholder: getPassesData.guestNames.length
                          ? ''
                          : 'Add names for all of your guests (Hit enter after each)',
                      }}
                      setValue={val =>
                        setGetPassesData(prev => ({
                          ...prev,
                          guestNames: val,
                        }))
                      }
                    />
                  </div>
                </div>
              )}
            </div>
            <h3 className={style.passOptLbl}>Pass Options:</h3>
            <PassOptions
              passInfoId={passInfoId.filter(passInfoFilter)}
              vehiclePassesByType={vehiclePassesByType}
              days={days}
              startDate={getPassesData.startDate}
              endDate={getPassesData.endDate}
              isGuestLink={isGuestLink}
              addVehiclePass={addVehiclePass}
              removeVehiclePass={removeVehiclePass}
              handleVehicleChange={handleVehicleChange}
              handleCheckboxChange={handleCheckboxChange}
              errors={passOptionErrors}
              priceSums={
                orderPriceLoading
                  ? null
                  : Object.fromEntries(
                      Object.entries(orderPricePassesByType || {}).map(
                        ([piid, prices]) => [
                          piid,
                          prices.reduce((sum, val) => sum + (val.price || 0), 0),
                        ],
                      ),
                    )
              }
            />
            {/* {console.log(passInfoId)}
            {passInfoId.map(passInfo => {
              console.log('passInfo', passInfo);
              const passInfoId = passInfo.passInfoId;
              const vehiclePasses = vehiclePassesByType[passInfoId] || [];
              // const duration = passInfo.durationInfo?.duration1;
              // if (duration && duration < days) {
              //   return null;
              // }
              return (
                <div key={passInfoId} className={`${style.mainInnerBox}`}>
                  <div className={style.innerBox}>
                    <div className={style.passOptions}>
                      <div className={style.passHeader}>
                        <h2>{passInfo.name}</h2>
                        <div className={style.row}>
                          <div>
                            <p style={{ margin: '10px 0px 0px 0px' }}>
                              {passInfo.description}
                            </p>
                          </div>
                          <div className={style.passCounter}>
                            {isGuestLink && (
                              <div className={style.priceContainer}>
                                <span className={style.price}>
                                  ${passPriceInfo[passInfoId]?.price || 0}/pass
                                </span>
                              </div>
                            )}
                            <div className={style.counterContainer}>
                              <button
                                type='button'
                                className={style.counterButton}
                                onClick={() => removeVehiclePass(passInfoId)}
                                disabled={vehiclePasses.length <= 0}
                              >
                                -
                              </button>
                              <span className={style.counter}>
                                {vehiclePasses.length}
                              </span>
                              <button
                                type='button'
                                className={style.counterButton}
                                onClick={() => addVehiclePass(passInfoId)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {vehiclePasses.map((vehiclePass, index) => (
                        <VehiclePassForm
                          key={index}
                          index={index}
                          vehiclePass={vehiclePass}
                          passInfoId={passInfoId}
                          handleChange={(index, field, value) =>
                            handleVehicleChange(passInfoId, index, field, value)
                          }
                          handleCheckboxChange={index =>
                            handleCheckboxChange(passInfoId, index)
                          }
                          smsAlert={false}
                          promotionalAlert={false}
                          setSmsAlert={function (): void {
                            throw new Error('Function not implemented.');
                          }}
                          setPromotionalAlert={function (): void {
                            throw new Error('Function not implemented.');
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              );
            })} */}
            <div className={style.innerBox}>
              <div style={{ margin: '20px 10px 10px 0px' }}>
                <h3>
                  Subtotal ({passes?.length}{' '}
                  {(passes?.length || 0) > 1 ? 'passes' : 'pass'})
                </h3>
              </div>
              <div className={style.totals}>
                {Object.entries(vehiclePassesByType || {}).map(([piid, vehiclePasses]) =>
                  vehiclePasses.length === 0 ? (
                    <></>
                  ) : (
                    <>
                      <div key={piid} className={style.totalBox}>
                        <div
                          className={style.row}
                          style={{ margin: '10px 10px 10px 0px' }}
                        >
                          <p>
                            ({vehiclePasses.length ?? 0}){' '}
                            {passInfoDatas.find(p => p.passInfoId === piid)?.name ||
                              'Access Pass'}
                          </p>
                          {isGuestLink && (
                            <p>
                              $
                              {orderPricePassesByType?.[piid]
                                ?.reduce<number>((sum, val) => sum + (val.price ?? 0), 0)
                                ?.toFixed(2) || '0.00'}
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  ),
                )}
                {orderPriceFees?.map(fee => (
                  <>
                    <div className={style.totalBox}>
                      <div className={style.row} style={{ margin: '10px 10px 10px 0px' }}>
                        <p>{fee.name || 'Fee'}</p>
                        <p>${fee.amount?.toFixed(2) || '0.00'}</p>
                      </div>
                    </div>
                  </>
                ))}
                {isGuestLink && (
                  <>
                    <hr className={style.horizontalLine} />
                    <div className={style.row} style={{ margin: '10px 10px 10px 0px' }}>
                      <h4>Total</h4>
                      <h4>
                        {(orderPricePasses?.length || 0) > 0
                          ? `$${orderPriceTotalPrice.toFixed(2)}`
                          : '$0'}
                      </h4>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={style.checkboxContainer} style={{ marginTop: '50px' }}>
              <input
                type="checkbox"
                checked={smsAlert}
                onChange={() => setSmsAlert(!smsAlert)}
              />
              <span className={style.checkboxText}>
                Opt in to receive important SMS notifications about emergencies, safety
                updates, and critical community alerts. We&apos;ll keep you informed
                during any urgent situations.
              </span>
            </div>
            <div className={style.checkboxContainer}>
              <input
                type="checkbox"
                checked={promotionalAlert}
                onChange={() => setPromotionalAlert(!promotionalAlert)}
              />
              <span className={style.checkboxText}>
                By checking this box, you agree to receive SMS alerts about upcoming
                events, community news, and special promotions. Don&apos;t miss out on
                important updates from our community!
              </span>
            </div>
            <div className={`${style.innerBox} ${style.buttonBox}`}>
              <div className={style.buttonContainer}>
                {!loading ? (
                  <button className={style.button} onClick={handleContinue} type="submit">
                    Continue
                  </button>
                ) : (
                  <button className={style.button} type="button">
                    Loading...
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
