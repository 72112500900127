import { gql } from '@apollo/client';
import { Download, Print } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// import { ReactComponent as DownLoad } from '../../../assets/download.svg';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import useGetMyGuests from '../../../common_lib_front/hooks/useMyGuest';
import useOrderPrice from '../../../common_lib_front/hooks/useOrderPrice';
import useProperty from '../../../common_lib_front/hooks/useProperty';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
import { printImageFile } from '../../../common_lib_front/utilities/printImageFile';
import { PassImageDisplay } from '../../../components/passGenerator/passGenerator';
import useVehicleConfigs from '../../../utils/useVehicleConfigs';
import NoVehicleAlert from './noVehicleAlert';
import style from './previewPasses.module.css';

const GET_PASS_IMAGE = gql`
  query GetPassImage($passId: String!) {
    getPassImage(passId: $passId) {
      success
      error
      data {
        passId
        number
        externalCredentialNumber
        url
      }
    }
  }
`;

const GET_PAYMENT_SESSION_BY_REGISTRATION = gql`
  query getPaymentSessionByRegistrationId($registrationId: String!) {
    getPaymentSessionByRegistrationId(registrationId: $registrationId) {
      success
      error
      data {
        registrationIds
        paymentSessionId
        createdBy
        checkoutSessionId
      }
    }
  }
`;

enum PassDeliveryMethods {
  instant,      // this is regular QR/pass image
  onceApproved, // this is regular QR/pass image BUT not delivered until admin approves
  physical,     // external (physical) credentials
}

export default function PreviewPasses(): ReactElement {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const { propertySlug, registrationId } = useParams<{
    propertySlug: string;
    registrationId: string;
  }>();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // call property details
  const {
    data: propertyDetail,
    loading: queryLoading,
    error: propertyError,
  } = useProperty({ propertySlug: propertySlug || '' });

  const navigateToPurchaseAnotherPass = () => {
    history.push(`/guest/${propertySlug}/get-passes`);
  };

  const { passInfoDatas } = useVehicleConfigs();
  const {
    data: myGuestDetails,
    error: guestDetailsError,
    loading,
  } = useGetMyGuests({
    registrationIds: registrationId ? [registrationId] : [],
    propertySlug: propertySlug,
  });
  console.log('[myGuestDetails]', myGuestDetails);

  type previewPassInfos = {
    passInfoId: string;
    startDate: string;
    endDate: string;
  };

  let passes: previewPassInfos[] = [];
  if (myGuestDetails && myGuestDetails.length > 0) {
    passes = myGuestDetails[0].passes.map(pass => ({
      passInfoId: pass.passInfoId,
      startDate: pass.startDate,
      endDate: pass.endDate,
    }));
  }

  const { totalPrice: total } = useOrderPrice(passes);
  // const { data, error } = usePaymentSession1({ variables: { registrationId: registrationId } });

  const paymentSessionExists = async () => {
    const { data } = await backendClient.query({
      query: GET_PAYMENT_SESSION_BY_REGISTRATION,
      fetchPolicy: 'no-cache',
      variables: { registrationId },
    });
    if (total > 0 && !data.getPaymentSessionByRegistrationId?.data) {
      history.push(`/guest/${propertySlug}/${registrationId}/payment-request`);
    }
  };

  paymentSessionExists();

  const handleDownloadClick = async (passId: string | undefined) => {
    try {
      const { data } = await backendClient.query({
        query: GET_PASS_IMAGE,
        fetchPolicy: 'no-cache',
        variables: { passId },
      });
      const url = data?.getPassImage?.data?.[0]?.url;
      if (url) {
        const link = document.createElement('a');
        link.download = 'access-pass.png';
        link.href = url;
        link.click();
      } else {
        console.error('Download URL is missing or invalid.');
      }
    } catch (error) {
      console.error('Failed to download the pass image:', error);
    }
  };
  const handlePrintClick = async (passId: string | undefined) => {
    try {
      const { data } = await backendClient.query({
        query: GET_PASS_IMAGE,
        fetchPolicy: 'no-cache',
        variables: { passId },
      });
      const url = data?.getPassImage?.data?.[0]?.url;
      if (url) {
        printImageFile([url]);
      } else {
        console.error('Download URL is missing or invalid.');
      }
    } catch (error) {
      console.error('Failed to download the pass image:', error);
    }
  };

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        // Navigate to a different route
        history.push(`/guest/${propertySlug}/get-passes`);
      }
    });

    // Clean up the listener when the component is unmounted
    return () => {
      unlisten();
    };
  }, []);

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <div className={style.passForm}>
          <div className={style.subHeaderTitle}>
            <h1 className={`${style.subHeaderTitle} textColorBlack`}>
              Congrats, your passes are below.
            </h1>
            <div className={style.subHeaderNote}>
              <p>
                Your passes have been sent to your email and phone. We’ll resend your
                passes just before your trip.
              </p>
              <p>
                If you have questions or need immediate assistance, please reach out to
                the host directly with the details provided with your reservation.
              </p>
            </div>
            <button
              className={style.button}
              onClick={navigateToPurchaseAnotherPass}
              type="submit"
            >
              {'Purchase another pass'}
            </button>
          </div>
          {passInfoDatas
            .filter(
              passInfoData =>
                passInfoData.portal === 'guest' &&
                myGuestDetails?.[0]?.passes.some(
                  p => p.passInfoId === passInfoData.passInfoId,
                ),
            )
            .map(passInfo => passInfo.deliveryMethod === PassDeliveryMethods.instant ? (
              <React.Fragment key={passInfo.passInfoId}>
                <div className={style.cardRow}>
                  <div
                    className={
                      myGuestDetails &&
                      myGuestDetails[0]?.passes.filter(
                        p => p.passInfoId === passInfo.passInfoId,
                      ).length > 1
                        ? style.cardColFull
                        : style.cardCol
                    }
                  >
                    <div className={style.cardItem}>
                      <p>
                        {passInfo?.name || ''}
                        <span>
                          (
                          {
                            myGuestDetails?.[0]?.passes.filter(
                              p => p.passInfoId === passInfo.passInfoId,
                            ).length
                          }
                          )
                        </span>
                      </p>
                      <div className={style.passesImgRow}>
                        {myGuestDetails?.[0]?.passes
                          .filter(p => p.passInfoId === passInfo.passInfoId)
                          .map((pass, index) => (
                            <div
                              className={
                                myGuestDetails?.[0]?.passes.filter(
                                  p => p.passInfoId === passInfo.passInfoId,
                                ).length > 1
                                  ? style.passesImg
                                  : style.passImage1
                              }
                              id={pass.passId}
                              key={pass.passId}
                            >
                              {!pass?.vehicles ? (
                                <NoVehicleAlert
                                  title={'Vehicle details missing'}
                                  linkTo={`/guest/${propertySlug}/${registrationId}/edit-vehicle`}
                                  color="red"
                                />
                              ) : (
                              <>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <GenericButton
                                    icon={className => <Download className={className} />}
                                    title="Download"
                                    clickHandler={() => handleDownloadClick(pass.passId)}
                                    color="transparent"
                                    outline="none"
                                    className={style.outlineButton}
                                    type="button"
                                  />
                                  {!(
                                    passInfoDatas.find(
                                      pinfo => pinfo.passInfoId === pass.passInfoId,
                                    )?.hidePrint ?? false
                                  ) && (
                                    <GenericButton
                                      // icon={className => <DownLoad className={className} />}
                                      icon={className => <Print className={className} />}
                                      title="Print"
                                      clickHandler={() => handlePrintClick(pass.passId)}
                                      color="transparent"
                                      outline="none"
                                      className={style.outlineButton}
                                      type="button"
                                    />
                                  )}
                                </div>
                                <PassImageDisplay passId={pass.passId} url={pass.url} />
                              </>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : null )}
          <div className={style.cardFull}>
            <div className={style.row}>
              <div className={style.col6}>
                <h5>Property details</h5>
                <p>
                  {propertyDetail?.address},{propertyDetail?.city},{propertyDetail?.state}
                  ,{propertyDetail?.zipCode}
                </p>
              </div>
              {propertyDetail?.enableContactDetails && (
                <div className={style.col6}>
                  <h5>Host details</h5>
                  <div className={style.hostdetails}>
                    <div>
                      <p>{propertyDetail?.propertyManagerName}</p>
                      <div>
                        <u>{propertyDetail?.propertyManagerPhone}</u>
                      </div>
                    </div>
                    <GenericButton
                      title="Call"
                      clickHandler={() => handleClickOpen()}
                      color="transparent"
                      outline="none"
                      className={style.outlineButton}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Call Host</DialogTitle>
        <DialogContent>
          <DialogContentText>
            you can call to host by using {propertyDetail?.propertyManagerPhone}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
