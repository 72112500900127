import { useMutation, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GuestInfo, { newGuestInfo } from '../../../common_lib_front/types/guestInfo';
import PassInfo, { newPassInfo } from '../../../common_lib_front/types/passInfo';
import VehicleInfo, { newVehicleInfo } from '../../../common_lib_front/types/vehicleInfo';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
import { PassPaymentCache } from '../wrapper/wrapper';
import {
  // GENERATE_IMAGE,
  // GENERATE_IMAGE_RES,
  // GENERATE_IMAGE_VARS,
  GET_VEHICLE,
  GET_VEHICLE_RES,
  GET_VEHICLE_VARS,
  STEP_FOUR_QUERY,
  STEP_FOUR_QUERY_RES,
  STEP_FOUR_QUERY_VARS,
  STEP_FOUR_SUMBIT,
  STEP_FOUR_SUMBIT_RES,
  STEP_FOUR_SUMBIT_VARS,
} from './secureCheckoutRequests';

export default function useSecureCheckout(nextHref: string): {
  userInfo: GuestInfo;
  registrationId: string;
  passes: Array<PassInfo>;
  redirect: string;
  submitHandler: (totalPrice: number) => void;
  alert: string;
  loading: boolean;
} {
  // eslint-disable-line indent
  const [passes, setPasses] = useState<PassInfo[]>([]);
  const [redirect, setRedirect] = useState<string>('');
  const { registrationId } = useParams<{ registrationId: string }>();
  const [userInfo, setUserInfo] = useState<GuestInfo>(newGuestInfo());
  const [alert, setAlert] = useState<string>('');

  // useQuery<GENERATE_IMAGE_RES, GENERATE_IMAGE_VARS>(GENERATE_IMAGE, {
  //   fetchPolicy: 'network-only',
  //   variables: {
  //     registrationId,
  //   },
  // });

  const { refetch } = useQuery<STEP_FOUR_QUERY_RES, STEP_FOUR_QUERY_VARS>(
    STEP_FOUR_QUERY,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      variables: {
        registrationId,
      },
      onCompleted: d => {
        const res = d.getPassesByRegistration.data?.map(p =>
          newPassInfo(p as Partial<PassInfo>),
        );
        if (res) {
          Promise.all(
            res.map((element, idx: number) =>
              backendClient
                .query<GET_VEHICLE_RES, GET_VEHICLE_VARS>({
                  query: GET_VEHICLE,
                  fetchPolicy: 'network-only',
                  variables: { passId: element.passId },
                })
                .then(v => {
                  res[idx].vehicle = newVehicleInfo(
                    v.data.getVehicle?.data as Partial<VehicleInfo>,
                  );
                })
                .catch(e => {
                  console.log(e);
                }),
            ),
          ).then(() => {
            setPasses(res);
            PassPaymentCache.push(res);
            setUserInfo(newGuestInfo(d.getGuestInfoData?.data as Partial<GuestInfo>));
          });
        }
      },
    },
  );

  const { communityId } = useContext(CommunityContext);

  const currentCommunity = useContext(CommunityContext);

  const [doSubmit, { loading }] = useMutation<
    STEP_FOUR_SUMBIT_RES,
    STEP_FOUR_SUMBIT_VARS
  >(STEP_FOUR_SUMBIT, {
    variables: {
      registrationId,
      successURL: `${window.location.origin}/${communityId}${nextHref}`,
      cancelURL: window.location.href,
      forceNew: false,
    },
    onError: err => {
      Sentry.captureMessage(err.message);
      setAlert('Something went wrong. Please try again later.');
      if (process.env.REACT_APP_DEBUG === 'true') {
        setRedirect(nextHref);
      } else {
        refetch().then();
      }
    },
    onCompleted: d => {
      if (d.doStripeCharge.success) {
        document.location.href = d.doStripeCharge.url;
        return;
      }
      if (d.doStripeCharge.error) {
        // setAlert(d.doStripeCharge.error); // forceNew apply when error faced. no need to display the error
      }
    },
  });

  const submitHandler = (price: number) => {
    if ((window as any).registrationLockStepNum >= 5) {
      setRedirect(nextHref);
      return;
    }

    if (loading) {
      return;
    }
    if (price == 0) {
      setRedirect(nextHref);
      return;
    }
    doSubmit().then(res => {
      if (
        currentCommunity.featuresConfig?.protectGuestDoubleCharge &&
        res.data?.doStripeCharge.error.includes('payment session already exists')
      ) {
        const forceNew =
          window.prompt(
            "We've detected that a payment session already exists for your pass. " +
              'Your host may have already paid for your pass or is in the process of doing so. ' +
              'It is also possible that you have paid or began to pay, but did not finish the process. ' +
              'If you understand and wish to proceed, please type "confirm" below to continue with payment. ' +
              'This will introduce a risk of double payment. ' +
              'If you have questions, please contact your host.',
            // "If you don't think your host should be paying for your pass, " +
            // 'wait 30 minutes and return to this page to attempt payment again. ' +
            // 'If issues persist, please contact your host. ' +
          ) === 'confirm';
        if (forceNew) {
          // retry
          doSubmit({
            variables: {
              registrationId,
              successURL: `${window.location.origin}/${communityId}${nextHref}`,
              cancelURL: window.location.href,
              forceNew,
            },
          });
        }
      } else {
        doSubmit({
          variables: {
            registrationId,
            successURL: `${window.location.origin}/${communityId}${nextHref}`,
            cancelURL: window.location.href,
            forceNew: true,
          },
        });
      }
    });
  };

  return {
    userInfo,
    registrationId,
    passes,
    redirect,
    submitHandler,
    alert,
    loading,
  };
}
